<template>
  <el-menu
    :default-active="$route.path"
    router
    background-color="#1e282c"
    text-color="#fff"
    :unique-opened="true"
  >
    <el-submenu v-for="(value, key) in allMenu" :key="key" :index="key">
      <template slot="title">
        <i :class="value.icon" style="font-size: 12px"></i>
        <span>{{ key }}</span>
      </template>
      <el-menu-item
        v-for="_value in value.children"
        :key="_value.name"
        :index="_value.name"
      >
        <span>{{ _value.key }}</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      allMenu: [],
    }
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getMenu();
  },
  methods: {
    getMenu() {
      let self = this;
      let allMenu = {
        调查人员: {
          icon: "el-icon-user-solid",
          children: [{ key: "人员列表", name: "/staff/list" }],
        },
        账号管理: {
          icon: "el-icon-user-solid",
          children: [{ key: "账号列表", name: "/admin/list" }],
        },
        现场调查: {
          icon: "el-icon-notebook-1",
          children: [
            { key: "检查表列表", name: "/patrol_survey/list" },
            { key: "检查表题库", name: "/patrol_survey/question_bank" },
            { key: "审核列表", name: "/patrol_survey_submit/list" }
          ]
        },
        满意度调查: {
          icon: 'el-icon-tickets',
          children:[
            {key: "满意度列表",name:'/project/satisfaction'},
            {key: "满意度题库", name: "/project/satisfaction_bank" }
          ]
        },
        项目管理: {
          icon: 'el-icon-potato-strips',
          children:[
            {key:"项目列表",name:'/project/list'}
          ]
        },
        区域管理: {
          icon: 'el-icon-potato-strips',
          children:[
            {key:"区域管理",name:'/province/list'},
            {key:"小区分组", name: '/province/groupList'},
          ]
        },
        数据统计: {
          icon: "el-icon-notebook-1",
          children: [
            { key: "项目列表", name: "/report/projectList" }
          ]
        },
        物业公司: {
          icon: "el-icon-notebook-1",
          children: [
            { key: "公司列表", name: "/company/list" }
          ]
        }
      };
      let estateMenu = {
        数据统计: {
          icon: "el-icon-notebook-1",
          children: [
            { key: "项目列表", name: "/report/projectList" }
          ]
        }
      };
      if (self.userInfo.positionLevel !== 6) {
        delete allMenu.用户管理;
      }
      if (self.userInfo.positionLevel === 1) {
        delete allMenu.我的任务;
      }
      self.allMenu = self.userInfo.role == 1? allMenu:estateMenu;
    }
  },
};
</script>
<style scoped>
.el-submenu {
  text-align: left;
}
</style>
