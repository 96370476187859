<template>
  <el-row class="header">
    <el-col :span="18">
      <div class="title" @click="histroyBack"><el-link type="primary" :underline="false"><i class="el-icon-caret-left"></i></el-link> {{GLOBAL.sysName}}</div>
    </el-col>
    <el-col :span="6">
      <el-dropdown @command="clickOptions" trigger="click">
        <span class="el-dropdown-link">
          {{ userInfo.account }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="deptName" disabled>{{userInfo.account}}·{{userInfo.account}}</el-dropdown-item> -->
          <!-- <el-dropdown-item command="changeUserInfo">修改个人信息</el-dropdown-item> -->
          <!-- <el-dropdown-item command="logOut" divided>退出登录</el-dropdown-item> -->
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name:'cwxHeader',
  data() {
    return {
      userInfo: {},
    };
  },
  mounted() {
    let self = this;
    self.userInfo  = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  beforeRouteLeave(to, from, next){ 
    if (to.path =='/index'){ 
      this.$vnode.parent.componentInstance.cache = {};
      this.$vnode.parent.componentInstance.keys = []
      } 
      next()
  },
  methods: {
    clickOptions(value) {
      let self = this;
      if (value === "logOut") {
        sessionStorage.setItem("userInfo", null);
        self.$router.replace({ path: "/" });
      }
      if(value === 'changeUserInfo'){
        self.$router.push({ name: "ChangeUserInfo" });
      }
    },
    histroyBack(){
      this.$router.back(-1)
    }
  },
};
</script>

<style scoped lang="scss">
.header{
  padding: 20px;
}
.el-dropdown {
  float: right;
  cursor: pointer;
}
.title{
  text-align: left;
}
</style>
