<template>
  <el-container>
    <el-aside width="200px"><cwxMenu /></el-aside>
    <el-container>
      <el-header><cwxHeader /></el-header>
      <el-main>
        <el-card shadow="never">
          <router-view />
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import cwxMenu from "@/components/cwxMenu.vue";
import cwxHeader from "@/components/cwxHeader.vue";
export default {
  name: "Home",
  components: {
    cwxHeader,
    cwxMenu,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style>
body {
  margin: 0;
}
el-card {
  padding: 30px;
}
.cardTitle {
  text-align: left;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  float: left;
  margin-right: 10px;
}
.cardTitleBtn{
  float: right;
}
</style>
